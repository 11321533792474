import { getTimeServer } from "../client/time.api";

export default async function getSyncTime() {
    try {
        // Record client timestamp just before sending the request
        const clientRequestTimestamp = Date.now();

        // Send the request to the server
        const res = await getTimeServer(clientRequestTimestamp);

        if (res) {
            // Record client timestamp immediately after receiving the response
            const clientResponseTimestamp = Date.now();

            // Extract server's timestamp
            const serverTimestamp = res.serverTime;

            // Calculate round-trip time (RTT)
            const roundTripTime =
                clientResponseTimestamp - clientRequestTimestamp;

            // Estimate one-way latency by dividing RTT by 2
            const estimatedLatency = roundTripTime / 2;

            // Adjust server time by adding the estimated latency (server time + latency to reach the client)
            const adjustedServerTime = serverTimestamp + estimatedLatency;

            // Calculate the difference between current client time and the adjusted server time
            const timeDifference = adjustedServerTime - clientResponseTimestamp;

            // Return the current "synced" server time by applying the time difference
            const syncedServerTime = Date.now() + timeDifference;

            return syncedServerTime;
        }
    } catch (err) {
        console.error("Error syncing time:", err);
    }
}
