import { defineStore } from "pinia";

export default defineStore("counter", {
    state: () => ({
        totalCoin: 0,
        idCount: null,
        widthProgress: 0,
    }),
    // actions: {
    //   saveCount(_count) {
    //     this.count = _count;
    //   },
    // },
});
