<template>
    <div
        class="flex flex-col items-center justify-center absolute top-[10px] left-[10px] cursor-pointer"
    >
        <div
            class="w-[50px] h-[50px] flex items-center justify-center rounded-lg icon-item"
        >
            <img
                :src="libIMG.HallEarn"
                alt="hall earn"
                class="w-9 aspect-auto swing"
            />
        </div>
        <h1 class="text-white font-neue-bold text-[14px] mt-[2px]">Earn</h1>
    </div>

    <div
        class="flex flex-col items-center justify-center absolute top-[10px] right-[10px] cursor-pointer"
    >
        <div
            class="w-[50px] h-[50px] flex items-center justify-center rounded-lg icon-item"
        >
            <img
                :src="libIMG.HallBoost"
                alt="hall earn"
                class="w-9 aspect-auto swing"
            />
        </div>
        <h1 class="text-white font-neue-bold text-[14px] mt-[2px]">Boost</h1>
    </div>

    <div
        class="flex flex-col items-center justify-center absolute bottom-[10px] left-[10px] cursor-pointer"
    >
        <div
            class="w-[50px] h-[50px] flex items-center justify-center rounded-lg icon-item"
        >
            <img
                :src="libIMG.HallStar"
                alt="hall earn"
                class="w-9 aspect-auto swing"
            />
        </div>
        <h1 class="text-white font-neue-bold text-[14px] mt-[2px]">Airdrop</h1>
    </div>

    <div
        class="flex flex-col items-center justify-center absolute bottom-[10px] right-[10px] cursor-pointer"
    >
        <div
            class="w-[50px] h-[50px] flex items-center justify-center rounded-lg icon-item"
        >
            <img
                :src="libIMG.HallAds"
                alt="hall earn"
                class="w-8 aspect-auto swing"
            />
        </div>
        <h1 class="text-white font-neue-bold text-[14px] mt-[2px]">Ads</h1>
    </div>
</template>

<script setup>
import libIMG from "../constant/imgConstant";
</script>

<style scoped>
.icon-item {
    border-radius: 12px;
    background: rgba(34, 27, 47, 0.6);
    backdrop-filter: blur(1.0499999523162842px);
}
.swing {
    transform-origin: center center; /* Set the point around which the element will rotate */
    animation: swing 2s ease-in-out infinite;
}

@keyframes swing {
    0% {
        transform: rotate(0deg) scale(0.85);
    }
    25% {
        transform: rotate(15deg) scale(0.9);
    }
    50% {
        transform: rotate(-15deg) scale(0.95);
    }
    75% {
        transform: rotate(10deg) scale(0.9);
    }
    100% {
        transform: rotate(0deg) scale(0.85);
    }
}
</style>
