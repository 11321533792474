<template>
    <div
        ref="target"
        class="max-w-full max-h-[300px] cursor-pointer"
        :class="[isMobile && 'mt-[-90px]']"
    ></div>
</template>

<script setup>
import * as THREE from "three";
// To allow for the camera to move around the scene
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
// To allow for importing the .gltf file
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import useUserAuthStore from "../stores/userAuthStore";
import getLV from "../utils/getLV";
import detectPlatform from "../utils/detectPlatform";

const checkPlatform = ref(detectPlatform());
const userAuthStore = useUserAuthStore();

const { game } = storeToRefs(userAuthStore);
// where three js append child
const target = ref();
// check isMobile or Pc
const isMobile = ref(window.innerHeight < 600 ? false : true);

//Create a Three.JS Scene
const scene = new THREE.Scene();
//create a new camera with positions and angles
const camera = new THREE.PerspectiveCamera(
    45,
    window.innerWidth / window.innerHeight,
    0.1,
    1000
);

//Keep the 3D object on a global variable so we can access it later
let object;

//OrbitControls allow the camera to move around the scene
let controls;

//Set which object to render
let objToRender = getLV(game.value?.props.storage.level)?.objToRender;

//Instantiate a loader for the .gltf file
const loader = new GLTFLoader();

// Load the file
loader.load(
    `/models/${objToRender}/scene.gltf`,
    function (gltf) {
        // If the file is loaded, add it to the scene
        object = gltf.scene;
        // set default scale for model
        object.scale.set(
            checkPlatform === "iOS" ? 0.8 : 1,
            checkPlatform === "iOS" ? 1.4 : 1,
            1
        );
        // Center the object
        const box = new THREE.Box3().setFromObject(object); // Create a bounding box
        const center = box.getCenter(new THREE.Vector3()); // Calculate the center of the bounding box

        object.position.x += object.position.x - center.x; // Adjust the object's x position
        object.position.y += object.position.y - center.y; // Adjust the object's y position
        object.position.z += object.position.z - center.z; // Adjust the object's z position

        scene.add(object); // Add the object to the scene
    },
    function (xhr) {
        //While it is loading, log the progress
        console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
    },
    function (error) {
        //If there is an error, log it
        console.error(error);
    }
);

//Instantiate a new renderer and set its size
const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true }); //Alpha: true allows for the transparent background
renderer.setPixelRatio(window.devicePixelRatio);
renderer.setSize(window.innerWidth - 40, isMobile.value ? 380 : 300);

//Set how far the camera will be from the 3D model
switch (objToRender) {
    case "bd_1":
        camera.position.z = 35;
        break;
    case "bd_2":
        camera.position.z = 25;
        break;
    default:
        camera.position.z = 45;
}

// Directional Light (Sunlight effect)
const directionalLight = new THREE.DirectionalLight(0xffffff, 2); // Strong light
directionalLight.position.set(10, 20, 10); // Position above and to the side

scene.add(directionalLight);

// Ambient Light (Soft global lighting)
const ambientLight = new THREE.AmbientLight(0x404040, 5); // Adjusted to prevent over-brightness
scene.add(ambientLight);

// Optional: Hemisphere Light (Natural lighting effect, like sky and ground light)
const hemisphereLight = new THREE.HemisphereLight(0xaaaaaa, 0x444444, 20); // (skyColor, groundColor, intensity)
scene.add(hemisphereLight);

//This adds controls to the camera, so we can rotate / zoom it with the mouse
controls = new OrbitControls(camera, renderer.domElement);
controls.autoRotate = true;
controls.autoRotateSpeed = 1;

// Set the max and min polar angles to limit vertical rotation
controls.maxPolarAngle = Math.PI / 2; // Prevent rotation above the horizon (90 degrees)
controls.minPolarAngle = Math.PI / 2; // Prevent rotation below the horizon (90 degrees)

//Render the scene
const animate = () => {
    requestAnimationFrame(animate);

    //Here we could add some code to update the scene, adding some automatic movement
    renderer.render(scene, camera);

    // control update to auto rotate
    controls.update();
};

onMounted(() => {
    //Add the renderer to the DOM
    target.value.appendChild(renderer.domElement);

    //Start the 3D rendering
    animate();
});
</script>
