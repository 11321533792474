import { defineStore } from "pinia";
import { loginTelegram, getUserInfo, sendInviteCode } from "../client/user.api";
import { startGame, claimGame } from "../client/game.api";
import { checkIn } from "../client/checkIn.api";
import { upgradeLV } from "../client/boost.api";
import { getTasks, sendStartTask, sendCompleteTask } from "../client/task.api";
import { getListItems, buyItem } from "../client/item.api";
import _ from "lodash";

const appDebugToken = import.meta.env.VITE_APP_DEBUG_TOKEN;

export default defineStore("auth", {
    state: () => ({
        user: null,
        token: null,
        checkedIn: false,
        daysCheckedIn: 0,
        game: null,
        tonBalance: 0,
        setTonBalance: false,
        tasks: null,
        items: null,
    }),
    actions: {
        async login(credentials) {
            if (appDebugToken) {
                this.token = appDebugToken;
                return;
            }
            try {
                const response = await loginTelegram(credentials);
                response.token && (this.token = response.token);
            } catch (error) {
                console.error("Login error: ", error);
            }
        },

        async getUserInfo(credentials) {
            try {
                if (!this.token) {
                    await this.login(credentials);
                }

                if (this.user) {
                    return;
                }

                if (this.token) {
                    const response = await getUserInfo(this.token);
                    if (response) {
                        this.user = response;
                        this.daysCheckedIn = response.days_checked_in;
                        this.checkedIn = response.checked_in;
                        this.game = response?.game;
                        return;
                    }
                }
            } catch (error) {
                console.error("Get user info error: ", error);
            }
        },

        async checkIn() {
            try {
                const response = await checkIn(this.token);

                if (response) {
                    this.checkedIn = true;
                    this.user.balance += response.amount;
                }
            } catch (error) {
                console.error("Login error: ", error);
            }
        },

        async startGame() {
            try {
                const response = await startGame(this.token);
                if (response) {
                    this.game = response?.data;
                    return true;
                }
            } catch (error) {
                console.error("Start game error: ", error);
            }
            return false;
        },

        async claimGame() {
            try {
                const response = await claimGame(this.token);
                if (response) {
                    this.user = response?.data?.user;
                    this.game = response?.data?.user?.game;
                    return true;
                }
            } catch (error) {
                console.error("Start game error: ", error);
            }
            return false;
        },

        async sendInviteCode(ref_code) {
            try {
                const response = await sendInviteCode(this.token, ref_code);
                if (response) {
                    delete response?.data?.game;
                    this.user = response?.data;
                    return true;
                }
            } catch (error) {
                console.error("Send invite code error: ", error);
            }
            return false;
        },

        async upgradeLV() {
            try {
                const response = await upgradeLV(this.token);
                if (response) {
                    if (this.game) {
                        this.game.level.level_number =
                            response?.data?.game?.level?.level_number;
                        this.game.level.upgrade_cost =
                            response?.data?.game?.level?.upgrade_cost;
                    }
                    delete response?.data?.game;
                    this.user = response?.data;
                    return true;
                }
            } catch (error) {
                console.error("Upgrade level error: ", error);
            }
            return false;
        },

        async getTasks() {
            try {
                const response = await getTasks(this.token);
                if (response) {
                    this.tasks = response?.data;
                    return true;
                }
            } catch (error) {
                console.error("Getting tasks error: ", error);
            }
            return false;
        },

        async sendStartTask(idTask) {
            try {
                const response = await sendStartTask(idTask, this.token);
                console.log(response);
                if (response) {
                    const task = _.find(this.tasks, {
                        id: response?.data?.task?.id,
                    });

                    if (!task) {
                        return false;
                    }
                    task.status = response?.data?.status;

                    return true;
                }
            } catch (error) {
                console.error("Getting tasks error: ", error);
            }
            return false;
        },

        async sendCompleteTask(idTask) {
            try {
                const response = await sendCompleteTask(idTask, this.token);
                if (response) {
                    const task = _.find(this.tasks, {
                        id: response?.data?.task?.id,
                    });

                    if (!task) {
                        return false;
                    }
                    task.status = response?.data?.status;

                    this.user = response?.data?.user;
                    return true;
                }
            } catch (error) {
                console.error("Getting tasks error: ", error);
            }
            return false;
        },

        async getListItems() {
            try {
                const response = await getListItems(this.token);
                if (response) {
                    this.items = response?.data;
                    return true;
                }
            } catch (error) {
                console.error("Getting list items: ", error);
            }
            return false;
        },

        async buyItem(itemId, type) {
            try {
                const response = await buyItem(itemId, this.token);

                if (response) {
                    this.user.balance = response.data?.balance;

                    this.game.started_at = response.data?.last_update_point_at;

                    const index = this.items?.findIndex(
                        (item) => item.id === itemId
                    );

                    if (index !== -1) {
                        this.items[index].is_bought = true;
                        switch (type) {
                            case "speed":
                                this.game.props.speed.value =
                                    this.items[index]?.value;
                                break;
                            case "amount":
                                this.game.props.amount.value =
                                    this.items[index]?.value;
                                break;
                            case "storage":
                                if (this.items[index]?.value === null) {
                                    this.items = this.items.map((item) => {
                                        if (item.type === "storage") {
                                            return { ...item, is_bought: true }; // Update the item with is_bought = true
                                        }
                                        return item; // Return the item unchanged if it doesn't meet the condition
                                    });
                                } else {
                                    this.items = this.items.map((item) => {
                                        if (
                                            item.type === "storage" &&
                                            item.value !== null &&
                                            Number(item.value) <
                                                Number(this.items[index]?.value)
                                        ) {
                                            return { ...item, is_bought: true }; // Update the item with is_bought = true
                                        }
                                        return item; // Return the item unchanged if it doesn't meet the condition
                                    });
                                    console.log(this.items);
                                }
                                this.game.props.storage.value =
                                    this.items[index]?.value || Infinity;
                                switch (this.items[index]?.value) {
                                    case "2000":
                                        this.game.props.storage.level = 1;
                                        break;
                                    case "3000":
                                        this.game.props.storage.level = 2;
                                        break;
                                    case "4000":
                                        this.game.props.storage.level = 3;
                                        break;
                                    case "5000":
                                        this.game.props.storage.level = 4;
                                        break;
                                    case null:
                                        this.game.props.storage.level = 5;
                                        break;
                                    default:
                                        break;
                                }
                                break;
                            default:
                                break;
                        }
                    }
                    return true;
                }
            } catch (error) {
                console.error("Getting list items: ", error);
            }
            return false;
        },
    },
});
