<template>
    <div
        class="h-[calc(100svh-150px)] flex flex-col items-center justify-center relative z-10 has-top-border"
    >
        <div
            class="absolute top-[25%] left-[10%] w-[120px] h-[45px] cursor-pointer"
        >
            <div class="relative">
                <img :src="libIMG.Board" class="w-full aspect-auto" alt="" />

                <div
                    class="absolute w-full h-full top-0 left-0 p-[6px]"
                    @click.prevent="() => handleOnClick('amount')"
                >
                    <div class="flex items-center justify-between">
                        <h3 class="text-white text-[14px]">Weapons</h3>
                        <p class="text-white text-[14px]">
                            {{
                                items?.filter(
                                    (item) =>
                                        item.type === "amount" &&
                                        item.is_bought === true
                                ).length
                            }}/{{
                                items?.filter((item) => item.type === "amount")
                                    .length
                            }}
                        </p>
                    </div>
                    <div
                        class="flex items-center justify-center gap-[2px] mt-[6px]"
                    >
                        <div
                            v-for="(item, index) in items?.filter(
                                (item) => item.type === 'amount'
                            )"
                            :key="index"
                            class="item"
                            :class="
                                item.type === 'amount' &&
                                item.is_bought === true
                                    ? 'opacity-100'
                                    : 'opacity-40'
                            "
                        ></div>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="absolute top-[5%] left-1/2 -translate-x-1/2 w-[120px] h-[45px] cursor-pointer"
        >
            <div class="relative">
                <img :src="libIMG.Board" class="w-full aspect-auto" alt="" />

                <div
                    class="absolute w-full h-full top-0 left-0 p-[6px]"
                    @click.prevent="() => handleOnClick('speed')"
                >
                    <div class="flex items-center justify-between">
                        <h3 class="text-white text-[14px]">Foods</h3>
                        <p class="text-white text-[14px]">
                            {{
                                items?.filter(
                                    (item) =>
                                        item.type === "speed" &&
                                        item.is_bought === true
                                ).length
                            }}/{{
                                items?.filter((item) => item.type === "speed")
                                    .length
                            }}
                        </p>
                    </div>
                    <div
                        class="flex items-center justify-center gap-[2px] mt-[6px]"
                    >
                        <div
                            v-for="(item, index) in items?.filter(
                                (item) => item.type === 'speed'
                            )"
                            :key="index"
                            class="item"
                            :class="
                                item.type === 'speed' && item.is_bought === true
                                    ? 'opacity-100'
                                    : 'opacity-40'
                            "
                        ></div>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="absolute top-[20%] right-[12%] w-[120px] h-[45px] cursor-pointer"
        >
            <div class="relative">
                <img :src="libIMG.Board" class="w-full aspect-auto" alt="" />

                <div
                    class="absolute w-full h-full top-0 left-0 p-[6px]"
                    @click.prevent="() => handleOnClick('storage')"
                >
                    <div class="flex items-center justify-between">
                        <h3 class="text-white text-[14px] capitalize">
                            storage
                        </h3>
                        <p class="text-white text-[14px]">
                            {{
                                items?.filter(
                                    (item) =>
                                        item.type === "storage" &&
                                        item.is_bought === true
                                ).length
                            }}/{{
                                items?.filter((item) => item.type === "storage")
                                    .length
                            }}
                        </p>
                    </div>
                    <div
                        class="flex items-center justify-center gap-[2px] mt-[6px]"
                    >
                        <div
                            v-for="(item, index) in items?.filter(
                                (item) => item.type === 'storage'
                            )"
                            :key="index"
                            class="item"
                            :class="
                                item.type === 'storage' &&
                                item.is_bought === true
                                    ? 'opacity-100'
                                    : 'opacity-40'
                            "
                        ></div>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="absolute bottom-[26%] left-[45%] w-[120px] h-[45px] cursor-pointer"
        >
            <div class="relative">
                <img :src="libIMG.Board" class="w-full aspect-auto" alt="" />

                <div
                    class="absolute w-full h-full top-0 left-0 p-[6px]"
                    @click.prevent="() => handleOnClick('speed')"
                >
                    <div class="flex items-center justify-between">
                        <h3 class="text-white text-[14px]">Cars</h3>
                        <p class="text-white text-[14px]">
                            {{
                                items?.filter(
                                    (item) =>
                                        item.type === "speed" &&
                                        item.is_bought === true
                                ).length
                            }}/{{
                                items?.filter((item) => item.type === "speed")
                                    .length
                            }}
                        </p>
                    </div>
                    <div
                        class="flex items-center justify-center gap-[2px] mt-[6px]"
                    >
                        <div
                            v-for="(item, index) in items?.filter(
                                (item) => item.type === 'speed'
                            )"
                            :key="index"
                            class="item"
                            :class="
                                item.type === 'speed' && item.is_bought === true
                                    ? 'opacity-100'
                                    : 'opacity-40'
                            "
                        ></div>
                    </div>
                </div>
            </div>
        </div>

        <FourIcon />
    </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import useAuthStore from "../stores/userAuthStore";
import libIMG from "../constant/imgConstant";
import detectPlatform from "../utils/detectPlatform";
import { FourIcon } from "../components";

const checkPlatform = ref(detectPlatform());
const router = useRouter();
const useAuth = useAuthStore();
const { items } = storeToRefs(useAuth);

onBeforeMount(async () => {
    if (!items.value) {
        await useAuth.getListItems();
    }
});

const handleOnClick = (typeName) => {
    router.push({ path: `/boost/${typeName}` });
};
</script>

<style scoped>
.item {
    border-radius: 1.59px;
    background: #fff;
    flex: 1;
    height: 6px;
    flex-shrink: 0;
}
</style>
