<script setup>
import { onMounted, ref, watch, onBeforeMount } from "vue";
import { storeToRefs } from "pinia";
import { ThreeDModel, FourIcon2 } from "../components";
import useCounterStore from "../stores/counter";
import useUserAuthStore from "../stores/userAuthStore";
import libIMG from "../constant/imgConstant";
import formatNumber from "../utils/formatNumber";
import getSyncTime from "../utils/getSyncTime";
import getLV from "../utils/getLV";

const counterStore = useCounterStore();
const userAuthStore = useUserAuthStore();
const { idCount, widthProgress, totalCoin } = storeToRefs(counterStore);
const { user, game } = storeToRefs(userAuthStore);
const storageShow = ref();
const lvText = ref(getLV(game.value?.props.storage.level)?.lvText);

const calculateBalance = async () => {
    // If dont have user'balance -> stop
    if (!user.value?.balance) return;

    // If dont have game info -> stop
    if (!game.value?.id) return;

    // Start time
    const startedTime = game.value?.started_at;

    // Current time
    const currentTime = await getSyncTime();

    // Calculate the difference for time passed and time left
    const passedDiffInMs = currentTime - startedTime;
    const coinEarn =
        parseFloat(
            game.value.props.speed.value * game.value.props.amount.value
        ) *
        (passedDiffInMs / 1000);

    totalCoin.value = Number(user.value?.balance) + Number(coinEarn);
    totalCoin.value = Math.round(Math.ceil(totalCoin.value * 1000) / 1000);

    if (
        game.value.props.storage.value !== null &&
        totalCoin.value >= game.value.props.storage.value
    ) {
        totalCoin.value = Number(game.value.props.storage.value);
        return;
    }

    if (totalCoin.value < 0) {
        totalCoin.value = 0;
    }

    if (idCount.value) {
        return;
    }

    // automate increase point
    idCount.value = setInterval(() => {
        if (
            game.value.props.storage.value !== null &&
            totalCoin.value >= game.value.props.storage.value
        ) {
            totalCoin.value = Number(game.value.props.storage.value);
            clearInterval(idCount.value);
            return;
        }

        totalCoin.value += parseFloat(
            game.value.props.speed.value * game.value.props.amount.value
        );
        totalCoin.value = Math.round(Math.ceil(totalCoin.value * 1000) / 1000);
    }, 1000);
};

onBeforeMount(async () => {
    if (!game.value) {
        try {
            const result = await userAuthStore.startGame();
            if (result) {
                user.value?.balance && runningGame();
            }
        } catch (error) {
            console.log(error);
        }
    }

    storageShow.value = game.value.props.storage.value
        ? formatNumber(Number(game.value.props.storage.value))
        : "Infinity";

    calculateBalance();
});

onMounted(() => {
    //Set odometer
    new Odometer({
        el: document.querySelector(".odometer-balance"),
        value: totalCoin.value,
        format: "(,ddd)",
    });
});

watch(
    totalCoin,
    () => {
        if (game.value.props.storage.value === null) {
            widthProgress.value = 100;
            return;
        }

        if (!game.value.props.storage.value) {
            return;
        }

        widthProgress.value = (
            (totalCoin.value / game.value.props.storage.value) *
            100
        ).toFixed(1);
    },
    { deep: true }
);
</script>

<template>
    <div
        class="relative h-[calc(100svh-150px)] w-full overflow-hidden flex flex-col items-center justify-between z-10"
    >
        <FourIcon2 />

        <div
            class="w-full flex flex-col items-center justify-center mt-1 pointer-events-none"
        >
            <div class="w-full flex flex-col items-center justify-center">
                <img
                    :src="libIMG.HomeTopBar"
                    alt=""
                    class="top-0 left-0 min-w-full aspect-auto"
                />
                <div
                    class="text-white flex items-center justify-center mt-[-32px]"
                >
                    <img
                        :src="libIMG.Logo"
                        class="w-6 h-6 rounded-full mr-[2px]"
                    />
                    <span class="odometer-balance font-neue-bold text-[18px]">{{
                        totalCoin
                    }}</span>
                </div>
            </div>
            <div
                class="w-[65%] flex items-center justify-between mt-4 pointer-events-none"
                v-if="
                    game?.props?.storage?.level ||
                    game?.props?.storage?.level === 0
                "
            >
                <div class="flex items-center justify-center">
                    <h1 class="blue-text font-neue-bold mr-[6px] text-[18px]">
                        Lvl.
                        {{ game.props.storage.level }}
                    </h1>
                    <p
                        class="blue-text text-[12px] font-semibold uppercase mt-1"
                    >
                        {{ lvText }}
                    </p>
                </div>
                <p class="blue-text font-neue-bold text-[16px] mr-1">
                    {{ storageShow }}
                </p>
            </div>
            <div
                class="relative w-[65%] rounded-full h-[30px] text-[#717171] font-neue-bold overflow-hidden mt-0 bg-transparent border-[2px] border-[#353041]"
                style="background: rgba(88, 5, 46, 0.2)"
                v-if="game"
            >
                <div
                    class="absolute top-1/2 -translate-y-[50%] left-0 h-[24px] progress rounded-full"
                    :style="{ width: widthProgress + '%' }"
                ></div>
            </div>
        </div>
        <ThreeDModel />
        <div class="h-8">
            <img
                :src="libIMG.Rotate"
                alt="rotate"
                class="w-[100px] pointer-events-none"
            />
        </div>
    </div>
</template>

<style scoped>
.bounce-button {
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
    animation: bounce 2s infinite;
}

.bounce-button:hover {
    background-color: #2980b9;
}

.bounce-button {
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
    animation: bounce 2s infinite;
}

.bounce-button:hover {
    background-color: #2980b9;
}

.progress {
    background: linear-gradient(90deg, #fa409d 0%, #9a2fff 100%);
    background-size: 100%;
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}
</style>
