<template>
    <div
        class="h-[calc(100svh-140px)] overflow-auto flex flex-col items-center justify-center px-4 z-10 has-top-border"
    >
        <div class="w-full flex flex-col items-center justify-center h-full">
            <div
                class="w-full flex flex-row items-center justify-between mt-2 mb-8"
            >
                <div class="w-[60%]">
                    <p
                        class="text-[20px] font-neue-bold pink-neon-text uppercase"
                    >
                        {{ countTaskAvailable }} tasks available
                    </p>
                    <p
                        class="text-[#FFF] opacity-60 text-[14px] leading-4 mt-3"
                    >
                        We'll reward you immediately with points after each task
                        completion.
                    </p>
                </div>
                <div class="relative h-full w-[40%]">
                    <img
                        :src="IMGS.BigCoin"
                        alt="logo"
                        class="absolute w-[95px] -translate-y-1/2 top-1/2 -translate-x-1/2 left-1/2"
                        height="auto"
                    />
                    <img
                        :src="IMGS.Coin1"
                        alt="logo"
                        class="absolute w-[25px] top-[-15px] right-0"
                        height="auto"
                    />
                    <img
                        :src="IMGS.Coin2"
                        alt="logo"
                        class="absolute w-[25px] bottom-[-32px] right-5"
                        height="auto"
                    />
                    <img
                        :src="IMGS.Coin3"
                        alt="logo"
                        class="absolute w-[25px] left-0 bottom-[-5px]"
                        height="auto"
                    />
                    <img
                        :src="IMGS.Coin4"
                        alt="logo"
                        class="absolute w-[25px] top-[-15px] left-0"
                        height="auto"
                    />
                </div>
            </div>

            <div class="overflow-auto relative w-full max-h-[65%] my-1">
                <div
                    class="w-full flex flex-col items-center justify-center"
                    v-for="(group, key) of groups"
                    :key="key"
                >
                    <h1
                        class="text-white text-[16px] font-neue-bold mb-[6px] self-start mt-[14px]"
                    >
                        {{ group.name }} ({{
                            _.filter(taskGroups[key] || [], { status: SUCCESS })
                                .length
                        }}/{{ taskGroups[key]?.length || 0 }})
                    </h1>
                    <div
                        v-if="(taskGroups[key]?.length || 0) <= 0"
                        class="text-gray-500 text-xs text-center my-3"
                    >
                        There are no missions available.
                    </div>
                    <TaskItem
                        v-for="task of taskGroups[key]"
                        :key="task.id"
                        :task="task"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { TaskItem } from "../components";
import IMGS from "../constant/imgConstant";
import groups from "../constant/groupTask";
import useAuthStore from "../stores/userAuthStore";
import { computed, onBeforeMount, ref, toValue } from "vue";
import _ from "lodash";
import { SUCCESS } from "../constant/taskStatus.js";

const useAuth = useAuthStore();
const { tasks } = storeToRefs(useAuth);

const taskGroups = computed(() => {
    return _.groupBy(toValue(tasks), "group");
});

const countTaskAvailable = computed(() => {
    return _.filter(toValue(tasks), (item) => item.status != SUCCESS).length;
});

onBeforeMount(async () => {
    if (!tasks.value) {
        await useAuth.getTasks();
    }
});
</script>
