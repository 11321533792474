<script setup lang="ts">
import { RouterLink } from "vue-router";
import libIMG from "../constant/imgConstant";
import detectPlatform from "../utils/detectPlatform";
import { ref } from "vue";

const checkPlatform = ref(detectPlatform());
</script>

<template>
    <div
        class="w-full fixed bottom-0 left-0 flex items-start justify-center h-[90px] z-50"
    >
        <div class="relative w-[92%] h-[100%]">
            <img
                :src="libIMG.BottomNavbarBG"
                alt="bottom bar"
                class="absolute top-0 left-1/2 -translate-x-1/2"
            />

            <div class="absolute top-0 left-1/2 -translate-x-1/2 w-[90%]">
                <div class="w-full mt-[6px] flex items-end justify-between">
                    <router-link
                        v-slot="{ isExactActive }"
                        :to="{ name: 'hall' }"
                        class="flex flex-col items-center justify-center opacity-70 w-[18%] h-full"
                    >
                        <div
                            class="flex flex-col items-center justify-center w-10 h-6"
                        >
                            <img
                                :src="
                                    isExactActive
                                        ? libIMG.HallIcon
                                        : libIMG.HallIcon2
                                "
                                alt="hall icon"
                            />
                        </div>
                        <p
                            class="text-[14px] mt-[2px]"
                            :class="
                                isExactActive
                                    ? 'blue-text font-neue-bold'
                                    : 'text-white '
                            "
                        >
                            Hall
                        </p>
                    </router-link>

                    <router-link
                        v-slot="{ isExactActive }"
                        :to="{ name: 'game' }"
                        class="flex flex-col items-center justify-center opacity-70 w-[18%] h-[50px]"
                    >
                        <div
                            class="flex flex-col items-center justify-center w-10 h-6"
                        >
                            <img
                                :src="
                                    isExactActive
                                        ? libIMG.GameIcon
                                        : libIMG.GameIcon2
                                "
                                alt="game icon"
                            />
                        </div>
                        <p
                            class="text-[14px] mt-[2px]"
                            :class="
                                isExactActive
                                    ? 'blue-text font-neue-bold'
                                    : 'text-white '
                            "
                        >
                            Game
                        </p>
                    </router-link>

                    <router-link
                        v-slot="{ isExactActive }"
                        :to="{ name: 'home' }"
                        class="flex flex-col items-center justify-center w-[18%] h-full"
                        ><div
                            class="!opacity-100 flex flex-col items-center justify-center w-20 h-12"
                        >
                            <img
                                :src="libIMG.HomeIcon"
                                alt="home icon"
                                class="!opacity-100 w-[66px] h-[66px]"
                            />
                        </div>

                        <p
                            class="home-item text-[14px] opacity-70"
                            :class="[
                                isExactActive
                                    ? 'blue-text font-neue-bold'
                                    : 'text-white ',
                                checkPlatform === 'iOS' ? 'mt-2' : 'mt-[2px]',
                            ]"
                        >
                            Home
                        </p>
                    </router-link>

                    <router-link
                        v-slot="{ isExactActive }"
                        :to="{ name: 'friend' }"
                        class="flex flex-col items-center justify-center opacity-70 w-[18%] h-full"
                    >
                        <div
                            class="flex flex-col items-center justify-center w-10 h-6"
                        >
                            <img
                                :src="
                                    isExactActive
                                        ? libIMG.FriendIcon
                                        : libIMG.FriendIcon2
                                "
                                alt="friend icon"
                            />
                        </div>

                        <p
                            class="text-[14px] mt-[2px]"
                            :class="
                                isExactActive
                                    ? 'blue-text font-neue-bold '
                                    : 'text-white '
                            "
                        >
                            Friends
                        </p>
                    </router-link>

                    <router-link
                        v-slot="{ isExactActive }"
                        :to="{ name: 'task' }"
                        class="flex flex-col items-center justify-center opacity-70 w-[18%] h-full"
                    >
                        <div
                            class="flex flex-col items-center justify-center w-10 h-6"
                        >
                            <img
                                :src="
                                    isExactActive
                                        ? libIMG.MissionIcon
                                        : libIMG.MissionIcon2
                                "
                                alt="mission icon"
                            />
                        </div>
                        <p
                            class="text-[14px] mt-[2px]"
                            :class="
                                isExactActive
                                    ? 'blue-text font-neue-bold '
                                    : 'text-white '
                            "
                        >
                            Missions
                        </p>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.router-link-exact-active {
    opacity: 1;
}

.router-link-exact-active .home-item {
    opacity: 1;
}

.has-border {
    border-color: rgba(255, 255, 255, 0.3);
}
</style>
