<template>
    <div
        class="sticky top-0 left-0 flex items-center justify-between w-full h-[60px] px-4 z-50"
    >
        <div class="flex items-center justify-center">
            <img
                :src="user?.avatar ? user?.avatar : libIMG.No_Avatar"
                class="rounded-lg w-[30px] h-[30px] mr-2"
            />
            <p class="text-white text-md font-neue-bold">
                {{ user?.full_name ? user?.full_name : "Username" }}
            </p>
        </div>

        <div class="flex items-center justify-center">
            <div class="parallelogram bg px-4 h-[34px]">
                <div
                    class="parallelogram-reverse flex items-center justify-center h-full"
                >
                    <img
                        :src="libIMG.Logo"
                        class="w-5 h-5 aspect-auto rounded-full"
                        alt="coin"
                    />
                    <p
                        class="leading-3 ml-[2px] text-[12px]"
                        v-if="miningSpeedPerHour"
                    >
                        <span class="font-neue-bold text-white">{{
                            miningSpeedPerHour && miningSpeedPerHour
                        }}</span>
                        <span class="text-white ml-1 opacity-45">/h</span>
                    </p>
                </div>
            </div>
            <div
                class="parallelogram bg px-3 h-[34px] text-white flex items-center ml-2 cursor-pointer"
                @click.prevent="handleOnclick"
            >
                <div
                    class="parallelogram-reverse flex items-center justify-center"
                >
                    <img :src="libIMG.Wallet" alt="wallet" class="w-9 h-9" />
                </div>
            </div>
        </div>
    </div>
    <ConnectTon
        :is-showed="isShowed"
        @click-unShow="handleOnclick"
        @click-connect="handleConnectTon"
    />
</template>

<script setup>
import { onBeforeMount, ref, watch } from "vue";
import { useTonWallet } from "@townsquarelabs/ui-vue";
import { useTonConnectModal } from "@townsquarelabs/ui-vue";
import { useTonConnectUI } from "@townsquarelabs/ui-vue";
import { storeToRefs } from "pinia";
import libIMG from "../constant/imgConstant";
import useUserAuthStore from "../stores/userAuthStore";
import { ConnectTon } from ".";
import { toast } from "vue3-toastify";
import formatNumber from "../utils/formatNumber";
import detectPlatform from "../utils/detectPlatform";

const checkPlatform = ref(detectPlatform());
const [tonConnectUI] = useTonConnectUI();
const wallet = useTonWallet();
const userAuthStore = useUserAuthStore();
const { user, game } = storeToRefs(userAuthStore);
const { open } = useTonConnectModal();
const isShowed = ref(false);
const miningSpeedPerHour = ref();

const handleOnclick = () => {
    isShowed.value = !isShowed.value;
};

const handleConnectTon = async () => {
    if (!wallet.value?.account?.address) {
        open();
    } else {
        await tonConnectUI.disconnect();
        toast.success("Disconnect your wallet successfully!");
    }
    isShowed.value = !isShowed.value;
};

onBeforeMount(() => {
    miningSpeedPerHour.value =
        game.value?.id &&
        parseFloat(
            game.value.props.speed.value * game.value.props.amount.value
        ) *
            60 *
            60;
    miningSpeedPerHour.value = Math.round(
        Math.ceil(miningSpeedPerHour.value * 1000) / 1000
    );
    miningSpeedPerHour.value = formatNumber(miningSpeedPerHour.value);
});

watch(
    game,
    () => {
        miningSpeedPerHour.value =
            game.value?.id &&
            parseFloat(
                game.value.props.speed.value * game.value.props.amount.value
            ) *
                60 *
                60;
        miningSpeedPerHour.value = Math.round(
            Math.ceil(miningSpeedPerHour.value * 1000) / 1000
        );
        miningSpeedPerHour.value = formatNumber(miningSpeedPerHour.value);
    },
    { deep: true }
);
</script>
