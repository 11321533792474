<script setup>
import { useRouter } from "vue-router";
import { onBeforeUnmount, onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { ProgressBar } from "../components";
import useAuthStore from "../stores/userAuthStore";
import libIMG from "../constant/imgConstant";
import isEmptyObj from "../utils/isEmptyObj.js";

const useAuth = useAuthStore();
const { tasks, items, user } = storeToRefs(useAuth);
const router = useRouter();
const progress = ref(0);

onMounted(async () => {
    console.log(window)
    // if (
    //     !window.Telegram.WebApp.initDataUnsafe ||
    //     isEmptyObj(window.Telegram.WebApp.initDataUnsafe)
    // ) {
    //     router.push({ path: "/error" });
    // }
    try {
        const telegramInitData = window.Telegram.WebApp.initDataUnsafe;
        await useAuth.getUserInfo(JSON.stringify(telegramInitData));
    } catch (err) {
        console.error(err);
    }
});

onMounted(() => {
    const plantInterval = setInterval(() => {
        progress.value += Math.floor(Math.random() * 11);
        if (progress.value >= 100) {
            progress.value = 100;
            clearInterval(plantInterval);
        }
    }, 150);
});

onBeforeUnmount(async () => {
    // get Ton wallet
    // const walletAddress = tonConnectUI.wallet?.account?.address;
    // if (walletAddress && !setTonBalance.value) {
    //     tonBalance.value = await getWalletBalance(walletAddress);
    //     setTonBalance.value = true;
    // }

    //get list all tasks
    if (!tasks.value) {
        await useAuth.getTasks();
    }

    //get list all items
    if (!items.value) {
        await useAuth.getListItems();
    }
});

watch(progress, () => {
    if (progress.value === 100 && user.value?.id) {
        router.push({ path: "/home" });
    }
});

watch(user, () => {
    if (progress.value === 100 && user.value?.id) {
        router.push({ path: "/home" });
    }
});
</script>

<template>
    <div
        class="w-full min-h-svh flex flex-col items-center pt-2 pb-8 justify-between relative"
    >
        <img
            :src="libIMG.LoadingBG_1"
            class="absolute top-0 left-0 w-full h-svh"
            alt="main bg"
        />
        <div class="flex flex-col items-center mt-8 z-20">
            <img :src="libIMG.LogoLoading" width="280" height="auto" />
        </div>

        <div class="w-[70%] z-20 mb-10">
            <ProgressBar :completed="progress" :title="'Loading...'">
            </ProgressBar>
        </div>
    </div>
</template>

<style scoped>
.bg {
    background-image: url("main_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
}
</style>
