import axios from "axios";
import { toast } from "vue3-toastify";

const backendURL = import.meta.env.VITE_BACKEND_URL;
const botName = import.meta.env.VITE_BOT_NAME;
const isLocal = import.meta.env.DEV;

const client = axios.create({
    baseURL: backendURL + "/api",
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
});
if (isLocal) {
    client.defaults.baseURL = "/api";
}

export async function loginTelegram(payload) {
    try {
        const response = await client.post(`/login/${botName}`, payload);
        if (response) return response?.data;
    } catch (error) {
        console.error("Error login:", error);
        const msg = error?.response?.data;
        if (typeof msg == "string") {
            toast.error(msg || "Something error");
            return;
        }
    }
}

export async function getUserInfo(token) {
    try {
        const response = await client.get(`/user/${botName}`, {
            headers: { Authorization: `Bearer ${token}` },
            withCredentials: true,
        });
        if (response) return response?.data;
    } catch (error) {
        console.error("Error get user info:", error);
        const msg = error?.response?.data;
        if (typeof msg == "string") {
            toast.error(msg || "Something error");
            return;
        }
    }
}

export async function sendInviteCode(token, ref_code) {
    try {
        const response = await client.post(
            `/user/ref/${botName}`,
            { ref_code },
            {
                headers: { Authorization: `Bearer ${token}` },
                withCredentials: true,
            }
        );
        if (response) return response;
    } catch (error) {
        console.error("Error send invite code:", error);
        let msg = error?.response?.data?.message;
        if (typeof msg == "string") {
            toast.error(msg || "Something error");
        }
    }
}
