export default function getLV(lv) {
    let objToRender, lvText;
    switch (lv) {
        case 1:
            objToRender = "bd_1";
            lvText = "Tent";
            break;
        case 2:
            objToRender = "bd_2";
            lvText = "Playground";
            break;
        case 3:
            objToRender = "bd_3";
            lvText = "Hotel";
            break;
        case 4:
            objToRender = "bd_4";
            lvText = "Skyscraper";
            break;
        case 5:
            objToRender = "bd_5";
            lvText = "Observatory";
            break;
        case 6:
            objToRender = "bd_5";
            lvText = "Observatory";
            break;
        default:
            objToRender = "bd_1";
            lvText = "Tent";
            break;
    }

    return { objToRender, lvText };
}
