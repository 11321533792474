export default {
    daily: {
        name: "Daily Checkin"
    },
    metaverse: {
        name: "Metaverse Tasks"
    },
    metaverse_node_operation: {
        name: "Metaverse Nodes Operation"
    },
    out_metaverse: {
        name: "Our Metaverse"
    },
};
