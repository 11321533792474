<template>
    <div
        class="flex items-center justify-between rounded-xl w-full py-4 pl-4 has-border my-[6px] transition duration-300"
        :class="{ 'cursor-pointer': task.status != SUCCESS }"
        @click="startTask"
    >
        <div class="flex justify-center flex-col">
            <h2 class="text-[#FFF] text-[16px] opacity-70">
                {{ task.title }}
            </h2>
            <div class="flex items-center mt-2">
                <img
                    :src="libIMG.Logo"
                    alt="logo"
                    class="w-7 h-7 mr-1 rounded-full"
                    :class="checkPlatform !== 'PC' ? 'mt-[-2px]' : 'mt-[2px]'"
                />
                <p class="text-[18px] white-pink-neon-text font-neue-bold">
                    +{{ new Intl.NumberFormat().format(task.amount) }}
                </p>
            </div>
        </div>

        <div class="pr-4" v-if="isPending">
            <svg
                class="animate-spin h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
            >
                <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                ></circle>
                <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
            </svg>
        </div>
        <TaskChecked :status="task.status" v-else />
    </div>
</template>

<script setup>
import TaskChecked from "./TaskChecked.vue";
import { SUCCESS } from "../constant/taskStatus";
import userAuthStore from "../stores/userAuthStore";
import { ref, toValue } from "vue";
import libIMG from "../constant/imgConstant";
import detectPlatform from "../utils/detectPlatform";
import { initUtils } from "@telegram-apps/sdk";

const utils = initUtils();
const checkPlatform = ref(detectPlatform());
const isPending = ref(false);
const authStore = userAuthStore();

const props = defineProps({
    task: { type: Object },
});

const startTask = async () => {
    const task = props.task;
    if (task.status == SUCCESS || toValue(isPending)) {
        return;
    }

    if (task.url && task.url !== "#") {
        utils.openLink(task.url, { tryInstantView: true });
    }

    isPending.value = true;

    await authStore.sendStartTask(task.id);
    // FIXME: thay logic này khi nghiệp vụ task rõ ràng
    setTimeout(async () => {
        await authStore.sendCompleteTask(task.id);
        isPending.value = false;
    }, 1000);
};
</script>

<style scoped>
.has-border {
    border-radius: 10px;
    border: 1px solid rgba(156, 204, 255, 0.16);
    box-shadow: 1px 1px 4px 0px rgba(255, 163, 163, 0.25);
}
</style>
