<script setup>
import { TopNavbar, BottomNavbar } from "../components";
import libIMG from "../constant/imgConstant";
</script>

<template>
    <div class="max-w-screen w-full max-h-svh">
        <img
            :src="libIMG.BGHall"
            class="fixed top-0 w-full h-svh left-0 -z-[2]"
            alt=""
        />
        <img
            :src="libIMG.BG_3"
            class="fixed top-0 w-full h-svh left-0 -z-[3]"
            alt=""
        />
        <img
            :src="libIMG.BG_5"
            class="fixed top-0 w-full h-svh left-0 -z-[4]"
            alt=""
        />
        <div class="fixed top-0 w-full h-svh left-0 -z-10 bg-black"></div>

        <TopNavbar />
        <slot />
        <BottomNavbar />
    </div>
</template>
